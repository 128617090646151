import { Button, Flex, Input, InputGroup, InputRightElement, FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import api from "api";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function JoinNowForm() {
    const [message, setMessage] = useState('');
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = data => {
        api.getWhitepaper().then(response => {
            console.log(response);
        })
        // Replace api.whitelist with your actual API call
        api.whitelist(data).then(response => {
            setMessage('Submitted successfully');
            reset();  // Reset the form fields after submission
        }).catch(error => {
            if (error.response && error.response.data.email) {
                setMessage(error.response.data.email);
            } else {
                setMessage('Submission failed, please try again.');
            }
        }).finally(() => {
            setTimeout(() => {
                setMessage(null)
            }, 2000)
        })
    };

    return (
        <Flex as="form" onSubmit={handleSubmit(onSubmit)} flexDirection={'column'} w={'60%'} mt={3}>
            <FormControl isInvalid={errors.email}>
                <InputGroup bg="green.100" borderRadius={20}>
                    <Input
                        placeholder='Email Address'
                        h={[55, 55, 65]}
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: "Invalid email address",
                            }
                        })}
                    />
                    <InputRightElement width='10rem' h="100%" display={['none', 'inline-grid', 'inline-grid']}>
                        <Button type="submit" size='lg' bg={'green.300'} h={14}>
                            JOIN NOW!
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage color={'white'}>
                    {errors.email && errors.email.message}
                </FormErrorMessage>
                {message && (
                    <Text mt={3} color="white" textTransform={'capitalize'}>
                        {message}
                    </Text>
                )}
            </FormControl>
            <Button type="submit" size='lg' bg={'green.300'} h={14} w={'100%'} mt={3} display={['inline-grid', 'none', 'none']}>
                JOIN NOW!
            </Button>

        </Flex>
    );
}
