import { Button, Center, Flex, FormControl, FormErrorMessage, Grid, GridItem, Input, Text } from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import api from "api";
import { useState, useMemo } from "react";
import { RECAPTCH_SITE_KEY, countries } from "utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-select';
import countryList from 'react-select-country-list';

export default function Form() {
    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
    const [message, setMessage] = useState('');
    const [isCaptchaVerified, setCaptchaVerified] = useState(false);

    // Get country options with flags
    const countryOptions = useMemo(() => {
        return countryList().getData().map(country => ({
            value: country.label,
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.value}.svg`}
                        alt={country.label}
                        style={{ width: '21px', marginRight: '8px' }}
                    />
                    {country.label}
                </div>
            ),
        }));
    }, []);

    function onChange(value) {
        setCaptchaVerified(value)
    }

    const onSubmit = data => {
        if (!isCaptchaVerified) {
            setMessage({text: 'Please make sure you complete the CAPTCHA correctly before submitting', color: 'red'})
            return
        }

        // Modify the country value before sending to API
        const formData = {
            ...data,
            country: data.country?.value
        };

        api.contact(formData).then(x => {
            setMessage({text: 'Thank You For Registering! You Will Be Notified Of The Official Launch Date', color: 'white'})
            reset()
        }).catch(e => {
            if (e.response) {
                console.log(e.response)
            } else {
                console.log(e)
            }
        })
    };

    return (
        <Flex bg="green.900" color={'white'} p={[10, 10, 100]}>
            <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={10} w={'100%'}>
                <Text fontSize={[28, 28, 66]} fontWeight={300}>
                    <Text fontWeight={600}>
                    Get Project
                    </Text>
                    Updates
                </Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid templateColumns={'1fr 1fr'} gap={6} w={['100%', '100%', '80%']}>
                        <GridItem colSpan={1}>
                            <FormControl isInvalid={errors.first_name}>
                                <Input
                                    placeholder="First Name"
                                    variant={'main'}
                                    {...register("first_name", { required: "First Name is required" })}
                                />
                                <FormErrorMessage>
                                    {errors.first_name && errors.first_name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <FormControl isInvalid={errors.last_name}>
                                <Input
                                    placeholder="Last Name"
                                    variant={'main'}
                                    {...register("last_name", { required: "Last Name is required" })}
                                />
                                <FormErrorMessage>
                                    {errors.last_name && errors.last_name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl isInvalid={errors.email}>
                                <Input
                                    placeholder="Email"
                                    variant={'main'}
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: "Invalid email address",
                                        }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.email && errors.email.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl isInvalid={errors.country}>
                                <Controller
                                    name="country"
                                    control={control}
                                    rules={{ required: "Country is required" }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={countryOptions}
                                            placeholder="Select Country"
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    color: 'var(--chakra-colors-green-500)',
                                                    backgroundColor: 'transparent',
                                                    borderColor: 'rgba(255, 255, 255, 0.3)',
                                                    '&:hover': {
                                                        borderColor: 'rgba(255, 255, 255, 0.3)'
                                                    }
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'white',
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? '#eee' : 'white',
                                                    color: 'black',
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: 'var(--chakra-colors-green-500)',
                                                }),
                                                placeholder: (base) => ({
                                                    ...base,
                                                    color: 'var(--chakra-colors-green-500)',
                                                }),
                                                input: (base) => ({
                                                    ...base,
                                                    color: 'var(--chakra-colors-green-500)',
                                                }),
                                                dropdownIndicator: (base) => ({
                                                    ...base,
                                                    color: 'var(--chakra-colors-green-500)',
                                                }),
                                                indicatorSeparator: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                }),
                                            }}
                                        />
                                    )}
                                />
                                <FormErrorMessage>
                                    {errors.country && errors.country.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl isInvalid={errors.first_invite_email}>
                                <Input
                                    placeholder="First Email address to Invite a friend"
                                    variant={'main'}
                                    {...register("first_invite_email", {
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: "Invalid email address",
                                        }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.first_invite_email && errors.first_invite_email.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl isInvalid={errors.second_invite_email}>
                                <Input
                                    placeholder="Second Email address to Invite a friend"
                                    variant={'main'}
                                    {...register("second_invite_email", {
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: "Invalid email address",
                                        }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.second_invite_email && errors.second_invite_email.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <FormControl isInvalid={errors.third_invite_email}>
                                <Input
                                    placeholder="Third Email address to Invite a friend"
                                    variant={'main'}
                                    {...register("third_invite_email", {
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: "Invalid email address",
                                        }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.third_invite_email && errors.third_invite_email.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={2}>
                            <Center> <ReCAPTCHA
                                sitekey={RECAPTCH_SITE_KEY}
                                onChange={onChange}
                            /></Center>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Button w={'100%'} bg="green.300" type="submit">JOIN NOW!</Button>
                        </GridItem>
                        <GridItem colSpan={2}>
                            {message && (
                                <Text mt={3} color={message?.color || "white"} textTransform={'capitalize'}>
                                    {message?.text}
                                </Text>
                            )}
                        </GridItem>
                    </Grid>
                </form>

            </Grid >
        </Flex >
    );
}
