import { Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";
import api from "api";
import { memo, useEffect, useState } from "react";
import { BACKEND_DOMAIN } from "utils/constants";

function ExecutiveSummay() {

    const [executiveSummary, setExecutiveSummary] = useState(null)
    const [whitepaper, setWhitepaper] = useState(null)

    useEffect(() => {
        api.files().then(x => {
            if(x.data.length) {
                const executiveSummary = x.data.find(x => x.file_type === "ES")
                const whitepaper = x.data.find(x => x.file_type === "WP")
                setExecutiveSummary(executiveSummary)
                setWhitepaper(whitepaper)
            }
        }).catch(e => {
            if (e.response) {
                console.log(e.response)
            } else {
                console.error(e)
            }
        })
    }, [])

    return <Flex
        bg="green.300"
        color={'green.900'}
        p={[10, 10, 100]}
        flexDir="column"
        alignItems={'center'}
    >
        <Image src="/images/landing_summary_icon.webp" h={206} w={206} />
        <Stack direction={["column", "column", "column"]} alignItems="center" fontSize={[28, 28, 66]} fontWeight={300}>
            <Stack direction={'row'}>
                <Text as="span" fontWeight={600}>
                    Executive
                </Text>{" "}
                <Text>
                    Summary
                </Text>
            </Stack>
            <Text>& White Paper</Text>
        </Stack>
       <Stack direction={['column', 'column', 'row']} spacing={[4, 4, 6]}>
       {executiveSummary && <Button as={Link} href={`${BACKEND_DOMAIN}/content/${executiveSummary.file}`}
            target='_blank'
            borderRadius={0}
            mt={10}
            bg={'green.900'}
            color='white'
            _hover={{ bg: 'green.400' }}
            size={'lg'}>View Executive Summary</Button>}
        {whitepaper && <Button as={Link} href={`${BACKEND_DOMAIN}/content/${whitepaper.file}`}
            target='_blank'
            borderRadius={0}
            mt={10}
            bg={'green.900'}
            color='white'
            _hover={{ bg: 'green.400' }}
            size={'lg'}>View White Paper</Button>}
       </Stack>
    </Flex>
}

export default memo(ExecutiveSummay)