import { Flex, IconButton, Link, Text } from "@chakra-ui/react";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
export default function SocialMediaLinks() {
  return (
    <Flex alignItems={"center"}>
      <Text
        as={Link}
        color={"white"}
        target="_blank"
        href="https://medium.com/@landdao_land"
        mr={3}
      >
        Blog
      </Text>
      <IconButton
            aria-label="Discord"
            icon={<FaDiscord />}
            borderRadius="full"
            bg="transparent"
            border="1px solid white"
            color="white"
            _hover={{ bg: 'gray.600' }}
            as={Link}
            target="_blank"
            href="https://discord.gg/4MntspzqcM"
            size={['md']}
        />
      <IconButton
        aria-label="Twitter"
        icon={<FaTwitter />}
        borderRadius="full"
        bg="transparent"
        border="1px solid white"
        color="white"
        _hover={{ bg: "twitter.600" }}
        ml={2}
        as={Link}
        target="_blank"
        href="https://twitter.com/landdao_land"
        size={["md"]}
      />

      {/* Removed Medium icon since it points to the same URL as Blog link */}

      <IconButton
        aria-label="Telegram  "
        icon={<FaTelegram />}
        borderRadius="full"
        bg="transparent"
        border="1px solid white"
        color="white"
        _hover={{ bg: "twitter.600" }}
            ml={2}
            as={Link}
            target="_blank"
            href="https://t.me/landdao_rwa"
            size={['md']}
        />
    </Flex>
  );
}
