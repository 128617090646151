const theme = {
  colors: {
    green: {
      100: "#EBF3F5",
      200: "#DDEBEC",
      300: "#66CDCC",
      400: "#ACE8E1",
      500: "#C9EDED",
      900: "#012E33",
    },
    yellow: {
      200: "#fcf7ed",
      400: "#FFEACC",
    },
    greenGradient:
      "linear-gradient(90deg, #012E33 0%, rgba(1, 46, 51, 0.5) 100%)",
    purple: {
      400: "#b9aafd",
    },
    gray: {
      200: "#F4F8F9",
    },
    gold: {
      400: "#F9D8A7",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        fontFamily: "Gellix, sans-serif",
        bg: "green.200",
        color: "green.900",
      },
    }),
  },
};

export default theme;
